.credit-account{
	background:$endeavour-blue;
	padding:40px 0;

	&-container{
		max-width:990px;
		margin:0 auto;
		@media only screen and (min-width: map-get($mq-breakpoints, 'desktop')) {
			display:flex;
			justify-content:space-between;
			align-items:center;
		}

		&__content{
			color:$white;
			font-size:30px;
			@media only screen and (max-width: map-get($mq-breakpoints, 'desktop')) {
				width:100%;
				text-align:center;
				font-size:24px;
				padding:0 20px;
			}

			p{
				margin:0;
			}
		}

		&__button{
			display:flex;
			justify-content:center;
			@media only screen and (max-width: map-get($mq-breakpoints, 'desktop')) {
				margin-top:20px;
			}
		}
	}
}