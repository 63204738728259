// Colour palette.
// ---------------------------------------------------------

$white: #fff;

$midnight-gray: #333;

$periwinkle-gray: #CCDCEB;

$black: #000;

$tjs-green: #41a728;

$tjs-orange: #ffad2d;

$endeavour-blue: #00529D;

$regal-blue: #004070;

$squeeze-blue: #E5EEF5;

$polo-blue: #80A9CE;

$astral-blue: #3375B0;

$downriver-blue: #0F2A59;

$lynch-blue: #6F7F9B;

$eastbay-blue: #3F557A;

$linkwater-blue: #D4E4F4;

$blizzard-blue: #B4E1EF;

$persian-red: #C42E2D;
