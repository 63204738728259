.account{
	padding-bottom:100px;
	@media only screen and (max-width: map-get($mq-breakpoints, 'desktop')) {
		padding:20px;
	}

	&__title{
		@media only screen and (max-width: map-get($mq-breakpoints, 'desktop')) {
			padding:0;
		}
	}

	&-container{

		&__button{
			@media only screen and (max-width: map-get($mq-breakpoints, 'desktop')) {
				width:100%;
				display:block;
				margin-bottom:15px;
				text-align:center;
			}
		}
	}
}
