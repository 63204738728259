#cookie-prompt {
    align-items: center;
    background: $white;
    border-top: 3px solid $tjs-green;
    bottom: 0;
    box-sizing: border-box;
    color: $text-color;
    display: flex;
    left: 0;
    line-height: 1.4;
    justify-content: center;
    padding: 1.2em 0;
    position: fixed;
    text-align: center;;
    transition: transform .8s ease;
    width: 100%;
    z-index: 99999999;
}

#cookie-prompt.is-active {
    transform: translateY(0);
}

#cookie-prompt,
#cookie-prompt.is-closing {
    transform: translateY(110%);
}

#cookie-prompt svg {
    display: inline-block;
    fill: currentColor;
    height: 1.4em;
    overflow: hidden;
    vertical-align: top;
    width: 1.4em;
}

#cookie-prompt p {
    display: inline-block;
    margin: .5em 1em;
    vertical-align: top;
}

#cookie-prompt p svg {
    height: 1.4em;
    margin-right: .5em;
    width: 1.4em;
}

#cookie-prompt a {
    color: $tjs-green;

    &:hover {
        color: $tjs-orange;
    }
}

#cookie-prompt button {
    @include button;
    @include button--size-s;
    @include button--skin-default;
    display: inline-block;
    font-size: inherit;
    font-weight: $font-weight--bold;
    line-height: 1;
    margin: .2em 1em;
    padding: .5em 1em !important;
    text-transform: uppercase;
}

#cookie-prompt button svg {
    height: 1em;
    margin-right: .6em;
    width: 1em;
}

// Responsive styles
@include mq($until: tablet-l) {
    #cookie-prompt {
        flex-wrap: wrap;
    }
}

