.credibility-bar{
	border-bottom:2px solid #E5EEF5;

	&-container{
		max-width:map-get($mq-breakpoints, 'desktop');
		margin:0 auto;
		padding:10px 0;
		display:flex;
		justify-content:space-between;

		&__item{
			display:flex !important;
			justify-content:center;
			align-items:center;
			text-decoration:none;
			color:$endeavour-blue;
		}

		&__image{
			margin-right:10px;
		}

		&__text{
			font-size:14px;
		}
	}
}

@media only screen and (max-width: map-get($mq-breakpoints, 'desktop')) {
	.credibility-bar{
		border-top:2px solid #E5EEF5;

		&-container{
			padding: 5px 0;
		}
	}
}