.contact{
	display:flex;
	padding-bottom:113px;
	@media only screen and (max-width: map-get($mq-breakpoints, 'desktop')) {
		padding:20px;
	}

	&-wrapper{
		width:100%;
		max-width:725px;
		@media only screen and (min-width: map-get($mq-breakpoints, 'desktop')) {
			margin-right:75px;
		}

		&__introduction{
			margin-bottom:50px;
		}

		&-form{
			display:flex;
			flex-wrap:wrap;
			justify-content:space-between;

			label{
				display:block;
				margin-bottom:5px;
				font-weight:$font-weight--bold;
			}

			&__text-field{
				height:61px !important;
			}

			&__section{
				@media only screen and (min-width: map-get($mq-breakpoints, 'desktop')) {
					max-width:348px;
				}
				width:100%;
				margin-bottom:25px;
			}

			&__section-textarea{	
				width:100%;
				margin-bottom:55px;
			}
		}
	}

	&-details{
		margin-top:166px;
		@media only screen and (max-width: map-get($mq-breakpoints, 'desktop')) {
			display:none;
		}

		&__title{
			font-weight:$font-weight--bold;
			font-size:30px;
			position:relative;
			padding-bottom:20px;

			&:before {
				content : "";
				position: absolute;
				left    : 0;
				bottom  : 0;
				height  : 4px;
				width   : 78px;
				border-bottom:4px solid $persian-red;
			}
		}

		&__list{
			margin-bottom:30px;
			padding:0;
			line-height:1.5;
		}

		&__address{
			list-style:none;
		}

		&__tel{
			color:$endeavour-blue;
			font-size:30px;
			text-decoration:none;
			font-weight:$font-weight--bold;
			position:relative;

			&:after{
				background: none repeat scroll 0 0 transparent;
				bottom: 0;
				content: "";
				display: block;
				height: 3px;
				left: 0;
				position: absolute;
				background: $persian-red;
				transition: width 1s ease 0s, left 0.5s ease 0s;
				width: 0;
			}

			&:hover:after{
				width:100%;
				left: 0;
			}
		}
	}
}

.g-recaptcha{
	width:100%;
	margin-bottom:52px;
}
