// Footer container.
// ---------------------------------------------------------
.payment-providers{
	border-top:2px solid $periwinkle-gray;

	&-container{
		max-width:map-get($mq-breakpoints, 'desktop');
		margin:0 auto;
		padding:43px 0px;
		display:flex;
		align-items:center;
		justify-content:center;
		flex-wrap:wrap;

		&__title{
			max-width:225px;
			font-size:22px;
			font-weight:$font-weight--semi-bold;
		}

		&__image{
			padding:0px 10px;
		}
	}
}

.layout-footer{
	background: $downriver-blue;

	&-container{
		max-width:map-get($mq-breakpoints, 'desktop');
		margin:0 auto;
		display:flex;
		justify-content:space-between;
		flex-wrap:wrap;
		padding-top:80px;
		padding-bottom:110px;

		&-column{

			&__title{
				position:relative;
				color:$white;
				font-size:22px;
				font-weight:$font-weight--bold;
				padding-bottom:20px;

				&-button{
					display:none;
				}

				&:before {
					content : "";
					position: absolute;
					left    : 0;
					bottom  : 0;
					height  : 4px;
					width   : 69px;
					border-bottom:4px solid $persian-red;
				}

			}

			&__list{
				font-size:18px;
				padding:0;
				margin:0;
			}

			&__item{
				list-style:none;
				line-height:2;
			}

			&__link{
				text-decoration:none;
				color:$white;	
			}

			&__address-line{
				list-style:none;
				color:$white;
				line-height:1.4;
			}

			&__social{
				width:115px;
				display:flex;
				justify-content:space-between;
				padding-bottom:40px;

				svg{
					width:30px;
					height:23px;
				}
			}

			&__tel-text{
				list-style:none;
				font-size:22px;
				color:$white;
			}

			&__tel-number{
				list-style:none;
				font-weight:$font-weight--bold;
				font-size:38px;
				color:$white;
				text-decoration:none;
			}
		}
	}
}

.layout-subfooter{
	background:#0A2244;

	&-container{
		max-width:1190px;
		margin:0 auto;
		display:flex;
		justify-content:space-between;
		flex-wrap:wrap;

		&__body{
			padding: 30px 0;
			color:$white;
		}

		&__list{
			display:flex;
			align-items:center;
			flex-wrap:wrap;
			list-style:none;
			color:$white;
			padding:0;
		}

		&__link{
			color:$white;
		}
	}
}

@media only screen and (max-width: map-get($mq-breakpoints, 'desktop')) {

	.payment-providers{
		border-top:2px solid $periwinkle-gray;

		&-container{
			text-align:center;
			padding:15px 0;

			&__title{
				max-width:unset;
				width:100%;
				font-size:20px;
			}

			&__image{
				padding:0px;
				width:80px;
			}
		}
	}
	.layout-footer{
		
		&-container{
		    display: grid;
		    grid-template-columns: repeat(auto-fill, 280px);
		    grid-template-rows: 1fr;
		    grid-column-gap: 20px;
		    grid-row-gap: 40px;
		    margin: 0 20px;
		}
	}

	.layout-subfooter{
		&-container{
			margin:0 20px;
		}
	}
}

@media only screen and (max-width: map-get($mq-breakpoints, 'tablet')) {

	.layout-footer{

		&-container{
			padding:40px 0;
		    grid-template-columns: repeat(auto-fill, 100%);

		    &-column{
		    	text-align:center;

				&__title{

					&:before{
						width:100%;
					}
				}

				&__social{
					margin:0 auto;
				}
			}
		}
	}

	.layout-subfooter{
		&-container{
			text-align:center;
			justify-content:center;

			&__seperator{
				display:none;
			}

			&__item{
				display:flex;
				flex-direction:column;
			}

			&__list{
				line-height:2;
			}
		}
	}
}
