.cableties-details{
	display:flex;
	align-items:center;
	@media only screen and (max-width: map-get($mq-breakpoints, 'tablet')) {
		flex-wrap:wrap;
	}

	&-container{
		width:100%;
		max-width:722px;

		&__title{
			position:relative;
			padding-bottom:20px;

			&:before {
				content : "";
				position: absolute;
				left    : 0;
				bottom  : 0;
				height  : 4px;
				width   : 78px;
				border-bottom:4px solid $persian-red;
			}
		}

		.cableties-specification{
			flex-direction:column;
		}
	}

	&-benefits{
		width:100%;
		max-width:400px;
		border: 3px solid $endeavour-blue;
		padding:40px;
		background:$white;
		margin-left:57px;
		@media only screen and (max-width: map-get($mq-breakpoints, 'tablet')) {
			margin-left:0;
			margin-top:30px;
			max-width:unset;
		}
		&__title{
			font-weight:$font-weight--bold;
			font-size:28px;
			margin:0;
		    padding-bottom:30px;
		}

		&__list{
		    list-style: none;
		    padding: 0;
		    margin: 0;

			&-item{
				padding-left: 30px;
			    padding-top: 10px;
			    padding-bottom: 10px;
				background: url(/images/placeholder/list-icon.png);
			    background-repeat: no-repeat;
			    background-position: 0% 50%;
			    line-height: 1;
			    font-weight: 500;
			}
		}
	}
}
