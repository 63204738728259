.button {
    @include button;
    @include button--size-default;
    @include button--skin-default;
}

.button--size-s {
    @include button--size-s;
}

.button--size-l {
    @include button--size-l;
}

.red-button{
	background:$persian-red;
	color:$white;
	text-decoration:none;
	font-weight:$font-weight--semi-bold;
	font-size:22px;
	transition: 0.4s;
	border:none;
	cursor:pointer;
	padding:12px 20px;
	border-radius:5px;

	&:hover{
		color:$white;
		background:$endeavour-blue;
	}
}

.blue-button{
	background:$endeavour-blue;
	color:$white;
	text-decoration:none;
	font-weight:$font-weight--semi-bold;
	transition: 0.4s;
	border:none;
	cursor:pointer;
	padding:19px 31px;
	border-radius:5px;
	font-size:22px;

	&:hover{
		background:$persian-red;
	}
}

.add-to-basket-button{
	background:$persian-red;
	color:$white;
	text-decoration:none;
	font-weight:$font-weight--semi-bold;
	transition: 0.4s;
	border:none;
	cursor:pointer;
	border:1px solid transparent;
	padding:12px 18px;

	&:hover{
		color:$white;
		background:none;
		border:1px solid $white;
	}
}

.white-button{
	background:$white;
	color:$endeavour-blue;
	border-radius:2px;
	text-decoration:none;
	font-weight:$font-weight--semi-bold;
	transition: 0.4s;
	border:none;
	cursor:pointer;
	padding:16px 20px;
	font-size:22px;
	display:block;

	// animation effect (slide left to right)
	background-repeat: no-repeat;
	background-size: 0% 100%;
	background-position: 0 100%;
	transition: all 0.2s linear;
	background-image: linear-gradient(120deg, $persian-red 0%, $persian-red 100%);

	&:hover{
		color:$white;
		background-size: 100% 100%;
	}
}


.transparent-button{
	background:none;
	color:$endeavour-blue;
	text-decoration:none;
	font-weight:$font-weight--semi-bold;
	border: 1px solid $endeavour-blue;
	transition: 0.4s;
	cursor:pointer;
	padding:16px 20px;
	font-size:20px;
	display:block;
	border-radius:5px;

	// animation effect (slide left to right)
	background-repeat: no-repeat;
	background-size: 0% 100%;
	background-position: 0 100%;
	transition: all 0.2s linear;
	background-image: linear-gradient(120deg, $endeavour-blue 0%, $endeavour-blue 100%);

	&:hover{
		color:$white;
		background-size: 100% 100%;
	}

	&.active{
		background:$endeavour-blue;
		color:$white;
	}
}