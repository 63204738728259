// Margin sizes.
// ---------------------------------------------------------

$margin-size: 24px;

$margin-size--l: 48px;
$margin-size--m: 24px;
$margin-size--xl: 72px;
$margin-size--s: 12px;
$margin-size--xs: 8px;

// Widget sizes.
// ---------------------------------------------------------

$widget-size: 44px;
$widget-size--l: 54px;
$widget-size--s: 36px;
