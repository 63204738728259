.pagination{
    display:flex;
    align-items:center;
    justify-content:center;
    flex-wrap:wrap;
    list-style:none;
    padding:20px;

    span,
    a{
        color:$endeavour-blue;
        font-size:16px;
        font-weight:600;
        text-decoration:none;
        padding:5px 10px;
    }

    a:hover{
        background:$endeavour-blue;
        color:$white;
    }

    li{
        margin-top:5px;
        margin-right:10px;
        display:flex;
        justify-content:center;
        align-items:center;
        border: 1px solid $linkwater-blue;
    }

    .active{
        background:$endeavour-blue;
        border: 1px solid $linkwater-blue;

        span{
            color:$white;
        }
    }

    .disabled{
        .previous{
            display:none;
        }

        .next{
            display:none;
        }
    }
}
