.cableties{
	&-heading{
		@media only screen and (max-width: map-get($mq-breakpoints, 'desktop')) {
			padding:20px;
		}

		&__title{
			padding-bottom:0;
			margin-bottom:15px;
		}
	}

	&-facets{
		margin-bottom:115px;
		@media only screen and (max-width: map-get($mq-breakpoints, 'desktop')) {
			margin:20px;
		}
	}

	&-products{
		@media only screen and (max-width: map-get($mq-breakpoints, 'desktop')) {
			padding:20px;
		}
		background-color:$squeeze-blue;

		&-container{
			padding-top:100px;
			padding-bottom:110px;

			&__title{
				font-size:30px;
				position:relative;
				padding-bottom:20px;
				margin-bottom:50px;
				font-weight:$font-weight--bold;

				&:before {
					content : "";
					position: absolute;
					left    : 0;
					bottom  : 0;
					height  : 4px;
					width   : 78px;
					border-bottom:4px solid $persian-red;
				}
			}

			&-results{
				display: grid;
			    grid-template-columns: repeat(auto-fill, 380px);
				@media only screen and (max-width: map-get($mq-breakpoints, 'tablet')) {
				    grid-template-columns: repeat(auto-fill, 100%);
				}
			    grid-template-rows: 1fr;
			    grid-column-gap: 20px;
			    grid-row-gap: 40px;
			}
		}
	}

	&-specification-wrapper{
		padding-top:85px;
		padding-bottom:85px;
		@media only screen and (max-width: map-get($mq-breakpoints, 'desktop')) {
			padding:20px;
		}
	}

	&-details-wrapper{
		padding:135px 0;
		background-color:$squeeze-blue;
		@media only screen and (max-width: map-get($mq-breakpoints, 'desktop')) {
			padding:20px;
		}
	}
}
