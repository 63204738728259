.blog{
	padding-bottom:130px;
	@media only screen and (max-width: map-get($mq-breakpoints, 'desktop')) {
		padding:20px;
	}

	&__content{
		max-width:740px;
		font-size:20px;
		font-weight:$font-weight--medium;
	}

	&-category{
		display: flex;
		@media only screen and (min-width: map-get($mq-breakpoints, 'desktop')) {
			flex-wrap:wrap;
		}
		margin-top:77px;
		margin-bottom:76px;
		@media only screen and (max-width: map-get($mq-breakpoints, 'desktop')) {
			margin:20px 0;
			overflow-x: scroll;
			position:relative;
			scrollbar-width:none;
			-ms-overflow-style: none;

			&::-webkit-scrollbar {
				width: 0 !important 
			}
		}

		&__background{
			background:$squeeze-blue;
		}

		&__link{
			margin-right:20px;
			margin-bottom:30px;
			margin-top:30px;
			flex-shrink:0;
		}
	}

	&-article{
		margin-bottom:100px;
		display: grid;
	    grid-template-columns: repeat(auto-fill, 375px);
		@media only screen and (max-width: map-get($mq-breakpoints, 'tablet')) {
			grid-template-columns: repeat(auto-fill, 100%);
	    }
	    grid-template-rows: 1fr;
	    grid-column-gap: 27px;
	    grid-row-gap: 60px;

	    &-container{
	    	color: $endeavour-blue;
	    	text-decoration:none;
    		position:relative;

	    	&__image{
	    		border: 2px solid $linkwater-blue;
	    		width:100%;
	 			text-align:center;
	    	}

	    	&__date{
	    		font-size:20px;
	    		margin-top:0;
	    	}

	    	&__title{
	    		font-weight:$font-weight--semi-bold;
	    		font-size:27px;	
	    		margin-bottom:20px;
	    	}

	    	&__link{
	    		color:$downriver-blue;
	    		text-decoration:none;
	    		font-size:20px;
	    		font-weight:$font-weight--medium;
	    		transition:0.3s;
	    		
	    		&:hover{
	    			color:$persian-red;
	    		}
	    	}
	    }
	}

	&-archive{
		background:$endeavour-blue;
		padding:50px 0;
		@media only screen and (max-width: map-get($mq-breakpoints, 'desktop')) {
			padding:20px;
		}

		&-container{
			max-width:map-get($mq-breakpoints, 'desktop');
			margin:0 auto;
			display:flex;
			justify-content:space-between;
			align-items:center;
			flex-wrap:wrap;



			&__title{
				font-size:40px;
				font-weight:$font-weight--semi-bold;
				color:$white;
				max-width:488px;
			}

			&__year{
				display:flex;
				flex-wrap:wrap;	
				justify-content:space-between;
				width:100%;
				@media only screen and (min-width: map-get($mq-breakpoints, 'desktop')) {
					max-width:640px;
					margin-top:15px;
				}
			}
			&__link{
				width:100%;
				@media only screen and (min-width: map-get($mq-breakpoints, 'desktop')) {
					max-width:310px;
				}
				display:flex;
				justify-content:space-between;
				padding-bottom:15px;
				margin-bottom:15px;
				border-bottom:1px solid $white;
				color:$white;
				text-decoration:none;
				transition:0.5s;
			}
		}
	}
}