// Breakpoints.
// ---------------------------------------------------------

$mq-breakpoints: (
    phone: 0px,
    phone-l: 448px,
    tablet: 650px,
    tablet-l: 768px,
    desktop: 1180px,
);
