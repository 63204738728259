select,
textarea {
    @include input;
    @include input--size-default;
}

input {
    &[type='text'],
    &[type='number'],
    &[type='email'],
    &[type='password'] {
        @include input;
        @include input--size-default;
    }
}

select {
    @include input--type-select;
}

textarea {
    // Allow only vertical resizing of textareas.
    resize: vertical;

    @include input--type-textarea;
}

/* Custom radio button */
.radio-container {
    display: flex;
    align-items:center;
    position: relative;
    padding-left: 20px;
    margin-bottom:5px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    
    &__label{
        cursor:pointer;
    }

    &__input{
        position: absolute;
        opacity: 0;
        cursor: pointer;

        &:checked ~ .radio-container__button{
            background-color: $linkwater-blue;
        }

        &:checked ~ .radio-container__button:after{
            display: block;
        }
    }

    &__button{
        margin: auto;
        position: absolute;
        top:0;
        left:0;
        bottom:0;
        height: 14px;
        width: 14px;
        background-color: $linkwater-blue;
        border-radius: 50%; 

        &:after{
            content: "";
            position: absolute;
            display: none;
            top: 3px;
            left: 3px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: $endeavour-blue;
        }
    }

    &:hover .radio-container__input:not(:checked) ~ .radio-container__button{
        background-color: #E5EEF5;
    }
}
