.homepage-slider{
	margin:0 auto;
	max-width:1600px;
	position:relative;


	&-wrapper{
		position:relative;
		@media only screen and (max-width: map-get($mq-breakpoints, 'desktop')) {
			max-height:450px;
		}

		&-content{
			position:absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			text-align:center;
			max-width:1200px;
			width:100%;
			padding:0 20px;
			font-size:26px;
			@media only screen and (max-width: map-get($mq-breakpoints, 'desktop')) {
				font-size:18px;
			}

			&__image{
				width:100%;
				height:auto;
			}

			&__title{
				font-size:50px;
				font-weight:$font-weight--semi-bold;
				margin-bottom:38px;
				@media only screen and (max-width: map-get($mq-breakpoints, 'desktop')) {
					font-size:32px;
					margin-bottom:15px;
				}
			}

			&__text{
				font-size:26px;
				margin:0 auto;
				margin-bottom:30px;
				@media only screen and (max-width: map-get($mq-breakpoints, 'desktop')) {
					font-size:18px;
					margin-bottom:15px;
				}
			}
		}

		&:hover{
			color:$endeavour-blue;
		}

	}

	&__nav{
		position:absolute;
		z-index:40;
		position:absolute;
		padding:20px;
		@media only screen and (max-width: map-get($mq-breakpoints, 'desktop')) {
			display:none !important;
		}

		&.next{
			top:calc(50% - 35px);
			right:0px;
		}

		&.prev{
			top:calc(50% - 35px);
			left:0px;
		}

		&-icon{
			height:44px;
			width:22px;
			cursor:pointer;
		}
	}
}