// Typography settings.
// ---------------------------------------------------------

$font-family: 'Montserrat', sans-serif;

$font-size: 16px;
$font-size--l: 24px;
$font-size--m: 14px;
$font-size--s: 12px;

$font-weight: 400;
$font-weight--light: 300;
$font-weight--medium: 500;
$font-weight--semi-bold: 600;
$font-weight--bold: 700;

$line-height: (20px / 16px) + .0001;
$line-height--l: (24px / 16px) + .0001;
$line-height--s: (19px / 16px) + .0001;

$text-color: $endeavour-blue;
$text-color--strong: $black;

$link-color: $endeavour-blue;
$link-color--active: $persian-red;
