.faq{
	padding-bottom:200px;
	@media only screen and (max-width: map-get($mq-breakpoints, 'desktop')) {
		padding:20px;
	}

	&__content{
		max-width:740px;	
		display:block;
		font-size:20px;
	}

	&-container{
		max-width:980px;

		&-category{
			margin-top:45px;
			margin-bottom:40px;
			font-weight:$font-weight--bold;
			font-size:28px;

			&-wrapper{
				border-bottom: 1px solid #E5EEF5;

				&__question{
					font-weight:$font-weight--bold;
					@media only screen and (min-width: map-get($mq-breakpoints, 'desktop')) {
						font-size:22px;
					}
					display:flex;
					align-items:center;
					cursor:pointer;

					&-button{
						color: $persian-red;
						font-size:35px;
						width:40px;
						flex-shrink:0;
					}
				}

				&__answer{
					margin-left:40px;
					@media only screen and (min-width: map-get($mq-breakpoints, 'desktop')) {
						font-size:21px;
					}
					display:none;
				}
			}
		}
	}
}
