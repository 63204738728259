.latest-articles{
	padding-bottom:108px;
	@media only screen and (max-width: map-get($mq-breakpoints, 'desktop')) {
		padding:20px;
	}

	&-background{
		background-image:linear-gradient(180deg, $linkwater-blue 0%, $linkwater-blue 50%, $white 50%, $white 100%);
	}
	&__title{
		font-size:47px;
		font-weight:$font-weight--semi-bold;
		margin:0px;
		padding:55px 0;
		text-align:center;
	}

	&-container{
		display:flex;
		@media only screen and (max-width: map-get($mq-breakpoints, 'desktop')) {
			margin:20px 0;
			overflow-x: scroll;
			position:relative;
			scrollbar-width:none;
			-ms-overflow-style: none;

			&::-webkit-scrollbar {
				width: 0 !important 
			}
		}

		& > div:nth-child(2){
			margin:0 20px;
		}
	}

	&-wrapper{
		text-decoration:none;

		&__image{
	    		border: 2px solid $linkwater-blue;
	 			text-align:center;
	    	}

	    	&__date{
	    		font-size:20px;
	    	}

	    	&__title{
	    		font-weight:$font-weight--semi-bold;
	    		font-size:27px;	
	    	}

	    	&__link{
	    		color:$persian-red;
	    		text-decoration:none;
	    		font-size:20px;
	    		font-weight:$font-weight--medium;
	    		transition:0.3s;
	    		
	    		&:hover{
	    			color:$endeavour-blue;
	    		}
	    	}
	}
}
