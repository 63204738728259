.facets{
	margin-top:60px;

	&-container{
		display:flex;
		justify-content:space-between;
		flex-wrap:wrap;

		&-input{
			width:100%;
			@media only screen and (min-width: map-get($mq-breakpoints, 'desktop')) {
				max-width:380px;
			}
			@media only screen and (max-width: map-get($mq-breakpoints, 'desktop')) {
				margin-bottom:15px;
			}

			&__label{
				font-weight:$font-weight--bold;
				margin-bottom:5px;
				display:block;
			}

			&__select{
				width:100%;
			    background: #fff url(/images/placeholder/select-down-arrow.png) 95% 50% no-repeat;
			}
		}
	}
}
