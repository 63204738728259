.about{

	&-container{
		margin-top:65px;
		margin-bottom:100px;
		display:flex;
		justify-content:space-between;
		flex-wrap:wrap;

		&__image{
			width:100%;
			@media only screen and (max-width: map-get($mq-breakpoints, 'desktop')) {
				order:2;
			}
			max-height:420px;
			max-width:487px;
		}
	}

	&-section{
		@media only screen and (max-width: map-get($mq-breakpoints, 'desktop')) {
			order:1;
			padding:20px;
		}
		max-width:560px;

		&__title{
			padding-top:0;
			margin:0;
			margin-bottom:40px;
			position:relative;

			&:before {
				content : "";
				position: absolute;
				left    : 0;
				bottom  : 0;
				height  : 4px;
				width   : 69px;
				border-bottom:4px solid $persian-red;
			}
		}

		&__subtitle{
			font-size:26px;
			font-weight:$font-weight--medium;
			margin:0;
		}

		&__content{
			font-size:17px;
			line-height:1.5;
		}

	}

	&-background{
		background:$squeeze-blue;
	}

	&-wrapper{
		padding:125px 0;
		@media only screen and (max-width: map-get($mq-breakpoints, 'desktop')) {
			padding:40px 20px;
		}

		&__title{
			padding-top:0;
			margin:0;
			margin-bottom:40px;
			position:relative;
			text-align:center;
			font-weight:$font-weight--bold;
			font-size:40px;
		}

		&__subtitle{
			font-size:26px;
			font-weight:$font-weight--medium;
			text-align:center;
			max-width:600px;
			margin:0 auto;
			margin-bottom:60px;
		}

		&__list{
			display:flex;
			justify-content:space-between;

			@media only screen and (max-width: map-get($mq-breakpoints, 'desktop')) {
				overflow-x: scroll;
				position:relative;
				scrollbar-width:none;
				-ms-overflow-style: none;

				&::-webkit-scrollbar {
					width: 0 !important 
				}
			}
		}

		&__item{
			width:100%;
			max-width:290px;
			flex-shrink:0;

			&-image{
				margin-bottom:60px;
			}

			&-title{
				font-size:25px;
				font-weight:$font-weight--semi-bold;
			}

			&-content{
				font-size:17px;
			}
		}
	}

	&-reviews{
		padding:125px 0;
		@media only screen and (max-width: map-get($mq-breakpoints, 'desktop')) {
			padding:40px 20px;
		}
		display:flex;
		flex-wrap:wrap;

		&-container{
			max-width:378px;

			&__title{
				padding-top:0;
				padding-bottom:20px;
				margin:0;
				margin-bottom:40px;
				position:relative;
				font-size:40px;

				&:before {
					content : "";
					position: absolute;
					left    : 0;
					bottom  : 0;
					height  : 4px;
					width   : 69px;
					border-bottom:4px solid $persian-red;
				}
			}

			&__content{
				font-size:17px;
				margin-bottom:35px;
			}
		}

		&-list{
			width:100%;
			max-width:780px;
			display: grid;
		    grid-template-columns: repeat(auto-fill, 380px);
		    grid-template-rows: 1fr;
		    grid-column-gap: 20px;
		    grid-row-gap: 40px;
		    margin-left:12px;
			@media only screen and (max-width: map-get($mq-breakpoints, 'desktop')) {
				margin-top:50px;
				margin-left:0;
			    grid-template-columns: repeat(auto-fill, 100%);
				max-width:unset;
			}
		}

		&-item{
			border:2px solid $linkwater-blue;
			border-radius:5px;
			padding:40px;

			&__icon{
				display:flex;
				align-items:center;
				margin-bottom:22px;
			}

			&__name{
				font-size:22px;
				font-weight:$font-weight--semi-bold;
			}

			&__content{
				font-size:17px;
				line-height:1.4;
			}
		}
	}
}
