// Viewport declaration.
// ---------------------------------------------------------

@-ms-viewport {
    width: device-width;
}

@viewport {
    width: device-width;
}

// Global overflow hidden
// ---------------------------------------------------------
html {
}

// Global typography.
// ---------------------------------------------------------
body {
    color: $text-color;
    font-family: $font-family;
    font-size: $font-size;
    font-weight: $font-weight;
    line-height: $line-height;
    min-width: 320px;
    overflow-x: hidden;
}

// Global `border-box` sizing.
// ---------------------------------------------------------
* {
    box-sizing: border-box;

    &::before,
    &::after {
        box-sizing: border-box;
    }
}

// Text-selection style.
// ---------------------------------------------------------

::selection {
    background: rgba($selection-color, .9);
    color: contrasting-color($selection-color);
    text-shadow: none;
}

// Reset anchor styles.
// ---------------------------------------------------------

a {
    color: $link-color;
    cursor: pointer;
    transition-property: background-color, color;
    transition-duration: $hover-duration;
    transition-timing-function: $hover-timing-function;

    &:active,
    &:hover {
        color: $link-color--active;
    }
}

// Reset <address> styles.
// ---------------------------------------------------------

address {
    display: block;
    font-style: inherit;
}

// Remove baseline gap from inline-block elements.
// ---------------------------------------------------------

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: top;
}

// Touch interaction.
// ---------------------------------------------------------

a,
label,
button {
    touch-action: manipulation;
}

.layout-container{
    max-width:map-get($mq-breakpoints, 'desktop');
    margin:0 auto;
}

.noscroll{
    overflow:hidden;
}

.page-banner{
    margin:0 auto;
    display:block;
    @media only screen and (max-width: map-get($mq-breakpoints, 'desktop')) {
        display:none;
    }
}
