.cableties-add-to-order{
	width:100%;

	&-container{
		padding:40px;
		@media only screen and (max-width: map-get($mq-breakpoints, 'tablet')) {
			padding:20px;
		}
		background:$white;

		&__title{
			width:100%;
			font-size:26px;
			font-weight:$font-weight--bold;
		}

		&__colour{
			padding:9px 20px;
			border:1px solid $endeavour-blue;
			font-size:23px;
		}

		&-table{
			margin-top:30px;
			border-collapse: collapse;
			font-size:18px;
			@media only screen and (max-width: map-get($mq-breakpoints, 'desktop')) {
				width:100%;
			}

				&__heading-description{
					text-align:left;
					width:50%;
					padding-bottom:15px;
					word-spacing:100px;
				}

				&__heading-price{
					text-align:left;
					width:30%;
					padding-bottom:15px;
				}

				&__cell-description{
					padding-top:20px;
					padding-bottom:10px;

					span{
						color:$polo-blue;
						font-size:14px;
					}
				}

				&__cell-price{
					vertical-align:top;
					text-align:right;
					padding-top:20px;
					padding-bottom:10px;
				}

			&__row{
				&:not(:first-child){
					border-top:1px solid $squeeze-blue;
				}
			}
		}
	}

	&-wrapper{
		background:$endeavour-blue;
		color:$white;

		&__total{	
			padding:30px 40px;
			@media only screen and (max-width: map-get($mq-breakpoints, 'tablet')) {
				padding:20px;
			}
			font-size:18px;
			display:flex;
			align-items:center;
			border-bottom:1px solid $astral-blue;
			margin:0;

			strong{
				font-size:35px;
				margin-left:10px;
			}
		}

		&-inputs{
			display:flex;
			justify-content:space-between;
			align-items:flex-end;
			padding:30px 40px;
			@media only screen and (max-width: map-get($mq-breakpoints, 'tablet')) {
				padding:20px;
			}
			
			&-quantity{
				width:87px;

				&__label{
					font-size:14px;
					margin-bottom:5px;
					display:block;
				}

				&__input{
					border-radius:0 !important;
					text-align:center;
					font-size:20px !important;
				}
			}
		}
	}
}