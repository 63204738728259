.review{
	margin-bottom:40px;
	@media only screen and (max-width: map-get($mq-breakpoints, 'desktop')) {
		margin:0 20px;
	}

	&-container{
		border-top:1px solid $squeeze-blue;
		padding-top:32px;
		padding-bottom:55px;

		&-rating{
			display:flex;
			align-items:center;

			&__icons{
				margin-right:20px;
			}

			&__name{
				display:block;
				margin-top:20px;
				font-size:25px;
				font-weight:$font-weight--semi-bold;
			}
		}

		&__message{
			max-width:800px;
			line-height:1.5;
		}
	}
}
