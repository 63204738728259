// Header container.
// ---------------------------------------------------------

.layout-header-nav{
	max-width:map-get($mq-breakpoints, 'desktop');
	margin:0 auto;
	padding:25px 0;
	display:flex;
	align-items:center;
	position:relative;

	.header-logo{
		margin-left:16px;

		svg{
			width:470px;
			height:50px;
		}
	}

	&-menu{
		list-style-type: none;
		display:flex;
		justify-content:space-between;
		width:100%;
		max-width:355px;
		margin-left:80px;
		padding:0;

	    &__item{
			font-size: 16px;
			position: relative;
	    }

	    &__link{
	    	color:$endeavour-blue;
	    	text-decoration:none;
			position:relative;    	
			border-bottom: 2px solid transparent;
			padding-bottom: 5px;

			&:hover{
				color:$persian-red;
				border-bottom:2px solid $persian-red;
			}

			&-active{
				color:$persian-red;
			}
	    }
	}

	&-vat-toggle{
		padding:0 29px 0 27px;

		&__radio{
			position:relative;

			&-label{
				font-size:14px;
				color:$endeavour-blue;
			}
		}
	}

	&-icons{
		width:100%;
		max-width:115px;
		display:flex;
		justify-content:space-between;

		svg{
			height:25px;
			width:28px;
			cursor:pointer;

			&:hover{
				color:$persian-red;
			}
		}

		.menu-burger,
		.menu-close{
			display:none;
		}
	}

	&-search{
		background: $downriver-blue;
		position:absolute;
		width:100%;
		z-index:98;
		padding: 65px 0;
		display:none;

		&-container{
			max-width:map-get($mq-breakpoints, 'desktop');
			margin:0 auto;
			position:relative;

			&__input{
				width:100%;
				background:none !important;
				border:none !important;
				border-bottom:2px solid $eastbay-blue !important;
				position:relative;
				color:$white !important;
				font-size:32px !important;
				height:64px !important;
				padding-right:50px !important;

				&::placeholder{
					color:$white !important;
					font-size:32px !important;
				}
			}

			&__button{
				position:absolute;
				right:10px;
				top:20px;
				background:none;
				color:$white;
				border:none;
				cursor:pointer;

				svg{
					height:25px;
					width:25px;
				}
			}

			@media only screen and (min-width: map-get($mq-breakpoints, 'desktop')) {

				&__triangle{
					position:absolute;
					right:89px;
					top:-83px;
					width: 0;
				    height: 0;
				    border-left: 18px solid transparent;
				    border-right: 18px solid transparent;
				    border-bottom: 18px solid $downriver-blue;
				}
			}
		}
	}

	&-mobile{
		display:none;
	}

	&-submenu{
		background:$endeavour-blue;

		&__container{
			max-width:map-get($mq-breakpoints, 'desktop');
			margin:0 auto;
			display:flex;
			justify-content: space-between;
			align-items:center;

			.navigation-dropdown{

				&:last-child{
					background:$persian-red !important;
				}

				&:hover{
					color:red !important;
					background:$regal-blue;

					.mega-menu {
						visibility: visible;
						opacity: 1;
					}
				}

				&-link{
					height:60px;
				    display: flex;
					text-decoration:none;
					color:$white;
				    position:relative;
				    padding:20px 17px;
				    font-weight:$font-weight--bold;
				}
			}

			.mega-menu {
				background: $regal-blue;
			  	left: 0;
			  	visibility: hidden;
			  	transition: visibility 0s, opacity 0.2s linear;
			  	opacity:0;
			  	position: absolute;
			  	width: 100%;
			  	z-index:9999;

				&__title {
					color: $white;
					font-size:34px;
					display:table;
					margin:0;
					width:283px;
					position:relative;
					padding-bottom:23px;

					&:before {
						content : "";
						position: absolute;
						left    : 0;
						bottom  : 0;
						height  : 4px;
						width   : 69px;
						border-bottom:2px solid $persian-red;
					}
				}


				&__content{
					display:flex;
					padding-top:80px;
					padding-bottom:95px;
					margin:0 auto;
					max-width:map-get($mq-breakpoints, 'desktop');
				}

				&__links{
					margin-left:110px;
					width:100%;
					display:flex;
					flex-wrap:wrap;
				}


				&__link{
					text-decoration:none;
					width:100%;
					max-width:240px;
					font-size:18px;
					color:$white;
					padding-bottom:20px;

					&:hover{
						color:$persian-red;
					}

					&:nth-child(3n+2){
						margin:0 20px;
					}
				}
			}
		}
	}
}

// Offset canvas.
// ---------------------------------------------------------

.offset-canvas{
	position: fixed;
	top: 60px;
	left: 0;
	background-color:$endeavour-blue;
	overflow:scroll;
	scrollbar-width:none; // hide on firefox
	-ms-overflow-style: none; // hide on IE
	z-index:100;
	height:100%;
	width:100%;
	display:none;

	&::-webkit-scrollbar {
		width: 0 !important; // hide on chrome
	}

	&-categories{
		position:relative;
		padding-left:20px;
		padding-right:20px;
		color:$white;
		font-size:20px;
		padding-top:30px;
		padding-bottom:35px;
	}

	&-category{
		justify-content:space-between;
		display:flex;
		align-items:cemter;
		cursor:pointer;
		list-style:none;
		padding:0;

		&__name{
			font-size:20px;
			margin-bottom:10px;

		}
	}

	&-subcategories{
		display:none;
		list-style:none;
		padding:0;

		&__close{
			margin-bottom:31px;
			display:flex;
			align-items:center;
			cursor:pointer;
			font-size:18px;
		}

		&__link{
			text-decoration:none;
			font-size:20px;
			color:$white;
		}
	}

	&-menu{
		background:$white;
		padding:25px 20px;

		&__item{
			margin-bottom:14px;
			list-style:none;
			padding:0;
		}

		&__link{
			color:$endeavour-blue;
			text-decoration:none;
			font-size:20px;
		}
	}

	&-bottom{
		padding:10px 20px;
	    margin-bottom: 150px;

		&__text{
			color:$white;
		}

		&__link{
			color:$white;
			text-decoration:none;
			font-size:20px;
		}

		&-vat-toggle{
			display:flex;

			&__radio-input{
				display:none;

				&:checked+label{
					font-weight:$font-weight--bold;
				}
			}

			&__radio-label{
				background:$white;
				padding:15px;
				font-size:17px;
				cursor:pointer;

				&:first-of-type{
					border-right:2px solid $periwinkle-gray;
				}
			}
		}
	}
}

@media only screen and (max-width: map-get($mq-breakpoints, 'desktop')) {
	.layout-header-nav{
		padding:10px 20px;
		display:flex;
		justify-content:space-between;

		.header-logo{
			margin-left:0px;

			svg{
				width:100%;
			}
		}

		&-menu{
			display:none;
		}

		&-vat-toggle{
			display:none;
		}

		&-icons{
			margin-left:20px;
			width:unset;
			max-width:unset;

			.menu-cart,
			.menu-search,
			.menu-account{
				display:none;
			}
			
			.menu-burger{
				display:block;
			}
		}

		&-mobile{
			display:flex;
			justify-content:space-between;
			align-items:center;
			padding:0px 20px; 
			background:$endeavour-blue;
			color:$white;

			&__contact{

				&-number{
					color:$white;
					font-weight:$font-weight--bold;
					text-decoration:none;
				}
			}

			&__icons{
				display:flex;
				justify-content:space-between;
				width:50px;
			}
		}

		&-search{
			padding: 30px 0;

			&-container{
				padding: 0 20px;

				&__input{
					width:100%;
					background:none !important;
					border:none !important;
					border-bottom:2px solid $eastbay-blue !important;
					position:relative;
					color:$white !important;
					font-size:24px !important;
					height:40px !important;
					padding-right:50px !important;

					&::placeholder{
						font-size:24px !important;
					}
				}

				&__button{
					right:20px;
					top:8px;

					svg{
						height:20px;
						width:20px;
					}
				}
			}
		}


		&-submenu{
			display:none;
		}
	}
}