.cableties-specification{
	display:flex;
	align-items:center;
	flex-wrap:wrap;
	@media only screen and (max-width: map-get($mq-breakpoints, 'desktop')) {
		justify-content:center;
	}

	&__image{
		width:100%;
		max-width:460px;
		height:100%;
		max-height:460px;
		border: 1px solid $periwinkle-gray;
	}

	&__order-form{
		@media only screen and (min-width: map-get($mq-breakpoints, 'desktop')) {
			width:540px;
			margin-left:60px;
		}

		.cableties-add-to-order-container__title{
			font-size:40px;
		}

		.cableties-add-to-order-container-table__heading-description{
			word-spacing:unset;
		}

		.cableties-add-to-order-container{
			padding-left:0;
			padding-right:0;
		}
	}

	&-container{
		margin-left:80px;
		width:100%;
		max-width:500px;

		@media only screen and (max-width: map-get($mq-breakpoints, 'desktop')) {
			margin:0;
		}

		&__title{
			font-size:30px;
			font-weight:$font-weight--bold;
			position:relative;
			padding-bottom:20px;

			&:before {
				content : "";
				position: absolute;
				left    : 0;
				bottom  : 0;
				height  : 4px;
				width   : 78px;
				border-bottom:4px solid $persian-red;
			}
		}

		&__list{
			list-style:none;
			padding:0;

			&-item{
				display:flex;
				justify-content:space-between;
				font-size:18px;
				padding-top:20px;
				padding-bottom:17px;

				&:not(:first-child){
					border-top: 1px solid $periwinkle-gray;
				}
			}
		}
	}
}
