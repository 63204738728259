.related-items{
	padding:140px 0;
	@media only screen and (max-width: map-get($mq-breakpoints, 'desktop')) {
		padding:20px;
	}

	&__title{
		position:relative;
		padding-bottom:20px;

		&:before {
			content : "";
			position: absolute;
			left    : 0;
			bottom  : 0;
			height  : 4px;
			width   : 78px;
			border-bottom:4px solid $persian-red;
		}
	}

	&-container{
		display:flex;
		@media only screen and (max-width: map-get($mq-breakpoints, 'desktop')) {
			overflow-x: scroll;
			position:relative;
			scrollbar-width:none;
			-ms-overflow-style: none;

			&::-webkit-scrollbar {
				width: 0 !important 
			}
		}
		margin-top:40px;

		&-item{
			width:100%;
			max-width:378px;
			flex-shrink: 0;

			&__image{
				width:100%;
				max-width:378px;
				height:100%;
				max-height:378px;
				border: 1px solid $periwinkle-gray;
			}

			&__title{
				font-size:24px;
				font-weight:$font-weight--bold;
			}

			&:nth-child(2){
				margin:0 20px;
			}
		}
	}
}
