.login{
	margin-top:100px;
	margin-bottom:100px;

	@media only screen and (max-width: map-get($mq-breakpoints, 'desktop')) {
		margin:20px;
	}

	&__title{
		font-weight:600;
	}

	&-form-error{
		margin-top:25px;
		padding:5px;
		color:white;
		width:100%;
		background:#EB001B;
	}


	&-form-label,
	&-form-reset{
		margin-top:30px;
		margin-bottom:5px;
		text-decoration:none;
		display:block;
	}

	&-form-button{
		margin-top:30px;
	}
}
